<nav class="navbar navbar-expand-lg fixed-top" aria-label="Main navigation">
    <div class="container-fluid">
      <a class="navbar-brand" href="#">
          <img src="assets/img/logoDesk.png" alt="">
      </a>
      <button class="navbar-toggler p-0 border-0" type="button" (click)="openMenu()" id="navbarSideCollapse" aria-label="Toggle navigation">
        <span class="navbar-toggler-icon"></span>
      </button>
  
      <div class="navbar-collapse offcanvas-collapse" [ngClass]="{'open': isOpen}" id="navbarsExampleDefault">
        <ul class="navbar-nav me-auto mb-2 mb-lg-0">
          <li class="nav-item">
            <a class="nav-link" (click)="openMenu();setTitle('Plusbarrent | Quiénes Somos')" routerLinkActive="active" [routerLink]="['quienesSomos']"  aria-current="page">Quiénes somos</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="openMenu();setTitle('Plusbarrent | Pólizas Jurídicas')" routerLinkActive="active" [routerLink]="['polizasJuridicas']" >Pólizas Jurídicas</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="openMenu();setTitle('Plusbarrent | Facturación')" routerLinkActive="active" [routerLink]="['facturacion']">Facturación</a>
          </li>
          <li class="nav-item">
            <a class="nav-link" (click)="openMenu();setTitle('Plusbarrent | Contacto')" routerLinkActive="active" [routerLink]="['contacto']">Contacto</a>
          </li>
        </ul>
      </div>
    </div>
  </nav>