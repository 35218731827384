<footer class="footer">
    <div class="container-fluid bg-footer" [ngStyle]="{'height': heightExpand ? '400px' : '255px'}">
        <!--
            ESCRITORIO
        -->
        <div class="row d-none d-md-flex">
            <div class="col-4">
                <h2>Plusbarrent</h2>
                <ul>
                    <li><a [routerLink]="['quienesSomos']">Quíenes Somos</a></li>
                </ul>
                <div class="redes-sociales">
                    <a href="https://www.facebook.com/plusbarrent" target="_blank"><img src="assets/img/footer/IconoFacebook.svg" alt="facebook"></a>
                    <a href="https://www.instagram.com/plusbarrent/" target="_blank"><img src="assets/img/footer/IconoInstagram.svg" alt="telegram"></a>
                    <a href="https://twitter.com/plusbarrent" target="_blank"><img src="assets/img/footer/IconoTwitter.svg" alt="twitter"></a>
                </div>
                <div class="avisoPrivacidad">
                    <a data-bs-toggle="modal" data-bs-target="#avisoPrivacidad"> Aviso de privacidad </a> | Todos los derechos reservados <sup>&copy;</sup>
                </div>
            </div>
            <div class="col-4">
                <h2>Pólizas Jurídicas</h2>
                <ul>
                    <li><a [routerLink]="['polizasJuridicas']">Bronce</a> </li>
                    <li><a [routerLink]="['polizasJuridicas']">Oro</a> </li>
                    <li><a [routerLink]="['polizasJuridicas']">Platino</a> </li>
                    <li><a [routerLink]="['polizasJuridicas']">Adicionales</a> </li>
                </ul>
            </div>
            <div class="col-4">
                <h2>Contacto</h2>
                <ul class="ul-footer">
                    Correo electrónico
                    <li>contacto@plusbarrent.com.mx</li>
                </ul>
                <ul class="ul-footer">
                    Dirección
                    <li>Eje 3 Oriente, Armada de México #3, Col. Cafetales, Mun. Coyoacán, C.P. 04918, Ciudad de México</li>
                </ul>
                <ul class="ul-footer">
                    Teléfonos
                    <li>55 - 8626 - 0911 | 55 - 8626 - 0912</li>
                </ul>
            </div>
        </div>
        <!--
            RESPONSIVO
        -->
        <div class="row d-block d-md-none">
            <mat-accordion>
                <mat-expansion-panel [expanded]="isOpen" (opened)="heightExpand = true" (closed)="heightExpand = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Plusbarrent
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul>
                        <li><a (click)="toggleExpansion()" [routerLink]="['quienesSomos']">Quíenes Somos</a></li>
                    </ul>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="isOpen" (opened)="heightExpand = true" (closed)="heightExpand = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Pólizas Jurídicas
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul>
                        <li><a (click)="toggleExpansion()" [routerLink]="['polizasJuridicas']">Bronce</a> </li>
                        <li><a (click)="toggleExpansion()" [routerLink]="['polizasJuridicas']">Oro</a> </li>
                        <li><a (click)="toggleExpansion()" [routerLink]="['polizasJuridicas']">Platino</a> </li>
                        <li><a (click)="toggleExpansion()" [routerLink]="['polizasJuridicas']">Adicionales</a> </li>
                    </ul>
                </mat-expansion-panel>
                <mat-expansion-panel [expanded]="isOpen" (opened)="heightExpand = true" (closed)="heightExpand = false">
                    <mat-expansion-panel-header>
                        <mat-panel-title>
                            Contacto
                        </mat-panel-title>
                    </mat-expansion-panel-header>
                    <ul class="ul-footer">
                        Correo electrónico
                        <li>contacto@plusbarrent.com.mx</li>
                    </ul>
                    <ul class="ul-footer">
                        Dirección
                        <li>Eje 3 Oriente, Armada de México #3, Col. Cafetales, Mun. Coyoacán, C.P. 04918, Ciudad de México</li>
                    </ul>
                    <ul class="ul-footer">
                        Teléfonos
                        <li>55 - 8626 - 0911 | 55 - 8626 - 0912</li>
                    </ul>
                </mat-expansion-panel>
              </mat-accordion>
              
        </div>
    </div>
    <div class="container-fluid bg-gris">
        <div class="redes d-flex d-md-none">
            <a href="https://www.facebook.com/plusbarrent" target="_blank"><img src="assets/img/footer/responsivo/IconoFacebook.svg" alt="facebook"></a>
            <a href="https://www.instagram.com/plusbarrent/" target="_blank"><img src="assets/img/footer/responsivo/IconoInstagram.svg" alt="instagram"></a>
            <a href="https://twitter.com/plusbarrent" target="_blank"><img src="assets/img/footer/responsivo/IconoTwitter.svg" alt="twitter"></a>
        </div>
        <div class="avisoPrivacidad text-center mt-2 d-block d-md-none">
            <a data-bs-toggle="modal" data-bs-target="#avisoPrivacidad"> Aviso de privacidad </a> <br> Todos los derechos reservados <sup>&copy;</sup>
        </div>
    </div>
</footer>


<!--
    AVISO DE PRIVACIDAD
-->

  <div class="modal fade" id="avisoPrivacidad" tabindex="-1" aria-labelledby="avisoPrivacidadLabel" aria-hidden="true">
    <div class="modal-dialog modal-dialog-centered modal-xl">
      <div class="modal-content">
        <div class="modal-header">
          <h5 class="modal-title" id="avisoPrivacidadLabel">PLUSBARRENT, S.A. DE C.V.</h5>
          <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
        </div>
        <div class="modal-body">
          <h5>Aviso de privacidad</h5>
          <p>
            <strong class="plus">PLUSBARRENT, S.A. DE C.V.</strong> con domicilio ubicado en Eje 3 Oriente, Armada de México #3, Colonia Cafetales, Municipio Coyoacán, C.P. 04918 pone a su disposición su Aviso de Privacidad en cumplimiento con la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, el cual podrá ser consultado en nuestro portal de internet <a href="www.plusbarrent.com.mx" class="link-pagina">www.plusbarrent.com.mx </a>
            Los datos recabados únicamente serán utilizados para proveer el servicio y la atención solicitada enfocada en operaciones jurídicas inmobiliarias y servicios a efectos de mejorar la calidad de los mismos.
          Para efectos de lo anterior y de conformidad con los artículos 36 y 37 de la Ley Federal de Protección de Datos Personales en Posesión de los Particulares, <strong class="plus">PLUSBARRENT, S.A. DE C.V.</strong> podrá transferir su información personal a empresas subsidiarias, así como a terceros proveedores que brinden servicios jurídicos, para la realización de los fines referidos.
          Los datos o información a solicitar de los cuales se mencionan entre otros de manera enunciativa más no limitativa los siguientes: Datos de contacto, datos laborales, datos financieros y documentación de soporte para la prestación de servicios que se solicita.
          De conformidad con los Derechos <strong>ARCO</strong>, usted tiene derecho a conocer con que datos personales contamos, para que son utilizados y las condiciones de uso, así mismo, podrá ejercer su derecho a solicitar la corrección de su información en caso de ser actualizada, ser inexacta o incompleta, así como que sea eliminada de nuestros sistema de registro cuando considere que la misma no	está siendo empleada conforme a los principios, deberes u obligaciones previstas en la normatividad, así como oponerse al uso de sus datos personales para fines específicos.
          Para ejercitar cualquiera de los Derechos <strong>ARCO</strong>, usted deberá presentar la solicitud por escrito através de correo electrónico a contacto@plusbarrent.com.mx, en el cual señale como mínimo los siguientes requisitos: : I) Nombre completo y datos personales necesarios para identificarlo y sobre los cuales es su deseo ejercer algún derecho completo y datos personales necesarios para identificarlo y sobre los cuales es su deseo ejercer algún derecho <strong>ARCO</strong>. II) El requerimiento del derecho <strong>ARCO</strong> a ejercer. III) Anexar documento para acreditar su identidad, y en su caso, IV) la manifestación expresa de revocar su consentimiento al tratamiento de sus datos personales.
          Recibida su solicitud el área correspondiente le informará por el mismo medio en un plazo no mayor a quince días contados a la partir de que se recibió dicho correo con la resolución adoptada.
          Si usted no manifiesta su oposición para que sus datos personales sean transferidos, se entenderá por otorgado su consentimiento para ello.
          De igual forma el titular garantiza que los datos proporcionados son fidedignos, exactos y completos por lo que se hace responsable con cualquier daño y perjuicio por falses, omisión o suplantación de identidad.
          El presente aviso de privacidad puede sufrir modificaciones, cambios o actualizaciones derivadas de nuevos requerimientos legales, de nuestra propia necesidad o servicios ofrecidos, de cambios de nuestro modelo de negocios o por cualquier otra causa.
          En caso de sufrir alguna modificación lo mantendremos informado sobre los cambios al presente aviso a través de nuestro portal de internet <a href="www.plusbarrent.com.mx" class="link-pagina"> www.plusbarrent.com.mx</a>
          </p>
        </div>
      </div>
    </div>
  </div>