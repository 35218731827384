import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';


const routes: Routes = [
  /*{ path: 'quienesSomos', component: QuienesSomosComponent },
  { path: 'polizaJuridica', component: PolizasJuridicasComponent },
  { path: 'contacto', component: ContactoComponent },
  { path: 'facturacion', component: FacturacionComponent },*/
  { 
    path: '', 
    loadChildren: () => import('./modulos/modulos.module').then(m => m.ModulosModule)
  }
];

@NgModule({
  imports: [RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules , scrollPositionRestoration: 'enabled'})],
  exports: [RouterModule]
})
export class AppRoutingModule { }
