import { Component, OnInit } from '@angular/core';
import { Title } from '@angular/platform-browser';
@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {

  isOpen:boolean = false;

  constructor(private titleService:Title) { }

  ngOnInit(): void {

  }

  openMenu() {
    this.isOpen = !this.isOpen;
  }
 
  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

}