import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders } from '@angular/common/http';
import { environment } from '../../environments/environment';

const apiUrl = "https://www.plusbarrent.com.mx";

@Injectable({
  providedIn: 'root'
})
export class ServicioGeneralService {

  constructor(private http: HttpClient) { }

  private ejecutarGetQuery<T>( query: string) {
    query = apiUrl + query;
    return this.http.get<T>(query);
  }

  private ejecutarPostQuery<T>( query: string, body:any) {
    query = apiUrl + query;
    return this.http.post<T>(query, body);
  }

  busquedaByCp(body) {
    return this.ejecutarGetQuery<any>(`/assets/php/comun/getInfoCp.php?cp=${body.cp}`);
  }

  factura(body){
    return this.ejecutarPostQuery<any>(`/assets/php/comun/facturacion/persiste.facturacion.php`, body);
  }

  enviaCorreo(body){
    return this.ejecutarPostQuery<any>(`/assets/php/comun/envia.email.php`, body);
  }

}